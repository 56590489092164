<template>
  <div id="bg" v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="head">
      <img  @click="flushed" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/flush.png" style="margin-right: 15px;margin-top:15px; width: 21px;height: 21px;float: right"/>
      <img @click="$router.push('/index')" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%201.png" style="margin-left: 15px;margin-top:15px;width: 21px;height: 21px;float: left"/>
    </div>

    <div style="height: auto; overflow-y: scroll;" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
       <div class="div3"  v-for="log in goldNumLog" :key="log.id" >
         <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/gold.png"/>
         <div v-if="log.goldNum > 0" style="float: left;position: absolute;right: 50px;top: 22px;color: rgba(255, 237, 129, 1);">+{{log.goldNum}}</div>
         <div v-else style="float: left;position: absolute;right: 50px;top: 22px;color: rgba(255, 237, 129, 1);">{{log.goldNum}}</div>
         <div style="float: left;position: absolute; color: rgba(171, 170, 201, 1);left: 10px;top: 5px;font-size: 15px" >{{log.desc}}</div>
         <div style="float: left;position: absolute; color: rgba(171, 170, 201, 1);left: 10px;top: 35px;font-size: 10px" >{{log.logDate}}</div>
       </div>
      <div v-if="goldNumLog==''">
        <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
        <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      checkDialog: false,
      goldNumLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      goldLog: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    flushed(){
      location.reload();
    },
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.goldLog.check == "login") {
        window.location.href = this.goldLog.appScheme + "login";
      } else {
        window.location.href = this.goldLog.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webGift/goldLog",
          method: "get",
          params: {
            pageNum: this.pageNum
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.log.forEach((element) => {
              this.goldNumLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
    this.$axios({
      url: "/webGift/goldLog",
      method: "get",
      params: {
        pageNum: 1
      }
    }).then((result) => {
      this.isLoading = false;
      if (result.data.code == 1) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else if (result.data.code == -2) {
        this.goldLog.check = result.data.data.check;
        this.goldLog.failMessage = result.data.message;
        this.goldLog.appScheme = result.data.data.appScheme;
        this.checkDialog = true;
      }
    });
  }
};
</script>
<style>
.div3{
  margin-top: 10px;
  position: relative;
  left: 15px;
  width: 90%;
  height: 55px;
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/goldBg.png") ;
  background-repeat: no-repeat;
  background-size: 100%,100%;
}
.div3 img{
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
}
html{
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
  background-size: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 100%;
}
</style>