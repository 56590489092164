<style>
.el-radio .el-radio__inner {
  opacity: 0;
}

.el-radio:checked .el-radio__inner {
  opacity: 1;}

.el-radio.divs.is-checked{
 background: #3cd191;

}

</style>
<template>
  <div id="bg">
    <div id="head">
      <img  @click="flushed" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/flush.png" style="margin-right: 15px;margin-top:15px; width: 21px;height: 21px;float: right"/>
      <img  @click="$router.push('/convert')"  src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%201.png" style="margin-left: 15px;margin-top:15px; width: 21px;height: 21px;float: left"/>
    </div>
    <div id="header2"></div>
    <div id="gd">
      <div id="gold"><img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/gold.png" style="width: 34px;height: 34px;margin-left: 16px;float: left"/>
      </div>
      <div id="goldNum" style="color: white">{{data.userInfo.goldNum}}</div>
    </div>
   <div class="word">{{this.giftCard.remarks}}</div>
    <el-radio-group v-model="convertRadio" style="display: inline-block !important;margin-top: 5%;width: 100%;height: 100%;">
          <span v-for="item in items" :key="item.itemId" style="margin-bottom: 3%;">
            <el-radio class="divs" :label="item.itemId" style="display: inline-block !important;margin: 2%;width: 28%;">
              <div style="text-align: left;padding: 1% 1% 1% 2%;">
                <img src="@/assets/common/goldIcon.png" style=" margin-right: 10px; position: absolute;bottom: 10px;left: 50px;height: 15px; width: 15px;" />
                <span  style="color: white; position: absolute;left:10px;bottom: 10px; font-size: 12px;vertical-align: middle;"> {{ item.gold }}</span>
              </div>
              <img :src="giftCard.image" style="position: absolute; width: 40px; height: 23px; left: 23px;">
              <div style="font-size: 15px;position: absolute;top:58px;left:13px;color: white">{{ item.goldValue }}</div>
            </el-radio>
          </span>
    </el-radio-group>
    <div style="position: absolute;left: 0;right: 0; bottom:20px;">
      <div style="position: relative;">
  <span style="position: absolute; bottom: 0px; left: 4%; transform: translateY(-50%); color: white;">
    {{ $t('convert.i_select') }}
  </span>
        <el-select v-model="currencyId" size="small" @change="getItems()" filterable style="width: 100px; position: absolute;right: 4%;bottom: 8px">
          <el-option v-for="currencies in giftCard.currencies" :key="currencies.id" :label="currencies.currencyEn" :value="currencies.currencyId" />
        </el-select>
      </div>
      <el-form ref="bindGiftCard" :model="bindGiftCard" :rules="formRules" style="text-align: center;margin: auto 4%;">
        <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
            <span v-if="form.keyName == 'account'" @click="updateAccount()" style="float: right;color: rgba(0, 183, 0, 1);text-decoration: underline;">
              {{$t('convert.i_editAccount')}}
            </span>
          <el-input v-if="form.keyName == 'account'" type="text" v-model="bindGiftCard.account" :placeholder="form.placeholder" />
          <el-input v-if="form.keyName == 'accountName'" type="text" v-model="bindGiftCard.accountName" :placeholder="form.placeholder" />
          <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="bindGiftCard.extraOne" :placeholder="form.placeholder" />
          <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="bindGiftCard.extraTwo" :placeholder="form.placeholder" />
        </el-form-item>
      </el-form>

      <div>
        <el-button type="primary" @click="toConvert('bindGiftCard')" style="color: black;margin-right: 1px; background-color: rgba(116, 209, 129, 1);width: 95%;margin-top: 8%;border-radius: 4px;">
          {{ $t('convert.i_redeemNow') }}
        </el-button>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_modifyReceivingAccount')" :visible.sync="updateDialog" width="100%" center>
      <div v-if="updateActive == 1">
        <el-form ref="updateForm" :model="updateForm" :rules="formRules" style="text-align: center;">
          <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
            <el-input v-if="form.keyName == 'account'" type="text" v-model="updateForm.account" :placeholder="form.placeholder" />
            <el-input v-else-if="form.keyName == 'accountName'" type="text" v-model="updateForm.accountName" :placeholder="form.placeholder" />
            <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="updateForm.extraOne" :placeholder="form.placeholder" />
            <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="updateForm.extraTwo" :placeholder="form.placeholder" />
          </el-form-item>
          <div style="padding-bottom: 8%;text-align: left;font-size: 11px;color: rgba(161, 134, 107, 1);">*{{ $t('convert.i_bindN') }}</div>
        </el-form>

        <div style="text-align: center;">
          <el-button type="primary" @click="onUpdateCheck('updateForm')" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
            {{ $t('convert.i_changeNow') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>

      <div v-if="updateActive == 2">
        <p>{{ $t('convert.i_changeT') }}</p>

        <div v-for="(form, index) in giftCard.forms" :key="index">
          <label>{{ form.label }}：</label>
          <div style="color: rgba(0, 183, 0, 1);">
            <p v-if="form.keyName == 'account'">{{ updateForm.account }}</p>
            <p v-if="form.keyName == 'accountName'">{{ updateForm.accountName }}</p>
            <p v-if="form.keyName == 'extraOne'">{{ updateForm.extraOne }}</p>
            <p v-if="form.keyName == 'extraTwo'">{{ updateForm.extraTwo }}</p>
          </div>
        </div>

        <div style="text-align: center;">
          <el-button type="primary" @click="toUpdateGiftCard()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">{{ $t('convert.i_confirm') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_convert') + ' - ' + convertTitle" :visible.sync="convertMsgDialog" width="100%" center :closeOnClickModal="convertCloseOnClickModal" :closeOnPressEscape="convertCloseOnPressEscape" :showClose="convertShowClose">
      <div style="text-align: center;">
        <div style="font-size: 16px;color: #000;">{{ convertMsg }}</div>
        <div style="margin-top: 10%;">
          <el-button type="primary" @click="convertOk()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_convert') + ' - ' + $t('convert.i_tips')" :visible.sync="convertDialog" width="100%" center>
      <div style="margin-bottom: 6%;text-align: center;">
        <img src="@/assets/images/convert002.png" style="margin-bottom: 6%;width: 70px;" />
        <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{ $t('convert.i_convertMsg') }}</div>
      </div>
      <div style="text-align: center;">
        <el-button type="primary" @click="$router.push('/convertLog')" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="convertBindDialog" width="100%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 6%;">
        <div style="font-size: 16px;color: #000;">{{ $t('convert.i_activateMsg') }}</div>
      </div>
      <div style="text-align: center;">
        <el-button type="primary" @click="$router.push('/moneySteps')" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_checkConvertInfo')" :visible.sync="checkConvertDialog" width="100%" center>
      <div v-for="item in items" :key="item.itemId">
        <div v-if="item.itemId == convertRadio">
            <span style="vertical-align: middle;">
              <span style="font-size: 22px;vertical-align: middle;"> {{ item.gold }}</span>
              <img src="@/assets/common/goldIcon.png" style="margin-left: 6px;width: 22px;vertical-align: middle;" />
            </span>
          <img src="@/assets/images/convert/convertB.png" style="margin: auto 18px;width: 18px;vertical-align: middle;" />
          <span style="font-size: 22px;vertical-align: middle;">{{ item.goldValue }}</span>
        </div>
      </div>

      <div v-for="(form, index) in giftCard.forms" :key="index" style="margin-top: 4%;">
        <label>{{ form.label }}：</label>
        <div style="color: rgba(0, 183, 0, 1);">
          <p v-if="form.keyName == 'account'">{{ bindGiftCard.account }}</p>
          <p v-if="form.keyName == 'accountName'">{{ bindGiftCard.accountName }}</p>
          <p v-if="form.keyName == 'extraOne'">{{ bindGiftCard.extraOne }}</p>
          <p v-if="form.keyName == 'extraTwo'">{{ bindGiftCard.extraTwo }}</p>
        </div>
      </div>

      <div style="margin-top: 12%;text-align: center;">
        <el-button type="primary" @click="onConvert()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
          {{ $t('convert.i_redeemNow') }}
        </el-button>
        <div style="margin-top: 4%;">
          <span style="color: rgba(144, 144, 144, 1);" @click="checkConvertDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
        </div>
      </div>
    </el-dialog>
  <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="116px" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
    <div style="text-align: center;margin-bottom: 10%;">
      <div style="font-size: 16px;color: #000;">{{ convertInfoParam.failMessage }}</div>
    </div>
    <div style="text-align: center;">
      <el-button round type="primary" @click="go()" style="width: 60%;">
        {{ $t('convert.i_ok') }}
      </el-button>
    </div>
  </el-dialog>

  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    const checkWhetherSpace = (rule, value, callback) => {
      this.giftCard.forms.forEach((form) => {
        if (form.keyName == rule.field) {
          if (form.regexps != null && !new RegExp(form.regexps).test(value)) {
            callback(new Error(form.regexpsTips));
          } else {
            callback();
          }
        }
      });
    };
    return {
      textToCopy:"http:www.kjjkdfj...",
      isLoading: false,
      checkDialog: false,
      findDialog:false,
      userInfoDialog: false,
      taskShow: 0,
      taskData: {},
      urlSchemes: "",
      goAppType: 0,
      actLeaderboard: 1,
      goldLeaderboard: {
        myRanking: {},
        leaderboardInfo: []
      },
      nameWith: 0,
      goldWidth: 0,
      leaderboard: {
        check: "",
        failMessage: "",
        appScheme: ""
      },
      task: {
        appName: "",
        check: "",
        failMessage: "",
        appScheme: ""
      },
      data: {
        appName: "",
        userInfo: {
          uid: 0,
          goldNum: 0,
          inviteCode: "",
          appScheme: "",
          check: "",
          failMessage: ""
        },

      },
      contactDrawer: false,
      contact: "https://www.facebook.com/people/Puzzle-Story/100089158787963/",
      convertTitle: "",
      convertMsg: "",
      convertShowClose: true,
      convertCloseOnPressEscape: true,
      convertCloseOnClickModal: true,
      convertBindDialog: false,
      convertStatus: 3,
      updateDialog: false,
      convertDialog: false,
      convertMsgDialog: false,
      checkConvertDialog: false,
      updateActive: 1,
      currencyId: "",
      convertRadio: "",
      giftCard: {},
      items: [],
      bindGiftCard: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      convertInfo: {
        itemId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      updateForm: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      formRules: {
        account: [
          {
            required: true,
            message: this.$t("convert.i_accountN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        accountName: [
          {
            required: true,
            message: this.$t("convert.i_nameN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraOne: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraTwo: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ]
      },
      regexps: "",
      regexpsTips: "",
      convertInfoParam: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    copyText() {
      const textToCopy = this.textToCopy;

      navigator.clipboard.writeText(textToCopy)
          .then(() => {
            alert("已成功复制文本: " + textToCopy);
          })
          .catch((error) => {
            console.error('复制失败:', error);
          });
    },
    flushed(){
      location.reload();
    },
    getTaskList() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/task",
        method: "get",
        params: {
          pageNum: 1
        }
      }).then((result) => {
        this.isLoading = false;
        console.log(result.data);
        this.taskData = result.data.data;
        if (result.data.code == 1) {
          this.$store.state.goldNum = result.data.data.goldNum;
          this.urlSchemes = result.data.data.appScheme;
          this.task.appName = result.data.data.appName;
        } else if (result.data.code == -2) {
          this.task.check = result.data.data.check;
          this.task.failMessage = result.data.message;
          this.task.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getIndex() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.data = result.data.data;
          this.data.userInfo.check = result.data.data.check;
          this.data.userInfo.failMessage = result.data.data.failMessage;
          this.$store.state.goldNum = result.data.data.userInfo.goldNum;
          localStorage.setItem("appScheme", this.data.userInfo.appScheme);
          if (result.data.data.webAppId == 10) {
            this.contact = "https://t.me/+JDzB_0CegZMwNWM9";
          }
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    goApp() {
      window.location.href = this.data.userInfo.appScheme;
    },
    goAppTask() {
      window.location.href = this.data.userInfo.appScheme+"task";
    },
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convertInfoParam.check == "login") {
        window.location.href = this.convertInfoParam.appScheme + "login";
      } else {
        window.location.href = this.convertInfoParam.appScheme;
      }
    },
    onUpdateCheck(formName) {

      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                      ["extraOne"],
                      (validErr) => {
                        if (!validErr) {
                          if (this.giftCard.forms.length >= 3) {
                            this.$refs[formName].validateField(
                                ["extraTwo"],
                                (validErr) => {
                                  if (!validErr) {
                                    this.updateActive = 2;
                                  }
                                }
                            );
                          } else {
                            if (!validErr) {
                              this.updateActive = 2;
                            }
                          }
                        }
                      }
                  );
                } else {
                  if (!validErr) {
                    this.updateActive = 2;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.updateActive = 2;
            }
          }
        }
      });
    },
    convertOk() {
      if (this.convertStatus == 1) {
        this.$router.go(-1);
      } else if (this.convertStatus == 3) {
        this.convertMsgDialog = false;
      }
    },
    updateAccount() {
      this.updateActive = 1;
      this.updateDialog = true;
    },
    getItems() {
      this.giftCard.currencies.forEach((currency) => {
        if (currency.currencyId == this.currencyId) {
          this.items = currency.items;
        }
      });
      this.convertRadio = this.items[0].itemId;
    },
    queryBindCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/queryBind?cardId=" + this.giftCard.cardId,
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        //console.log(result.data);
        if (result.data.code == 1) {
          if (result.data.data.isActivation == false) {
            this.convertBindDialog = true;
          } else {
            if (
                result.data.data.updateLog == null ||
                result.data.data.accountBind == null
            ) {
              if (result.data.data.accountBind == null) {
                //this.bindDialog = true;
              } else {
                this.bindGiftCard.account =
                    result.data.data.accountBind.bindAccount;
                this.bindGiftCard.accountName =
                    result.data.data.accountBind.accountName;
                this.bindGiftCard.extraOne =
                    result.data.data.accountBind.extraOne;
                this.bindGiftCard.extraTwo =
                    result.data.data.accountBind.extraTwo;
                this.updateForm.account = this.bindGiftCard.account;
                this.updateForm.accountName = this.bindGiftCard.accountName;
                this.updateForm.extraOne = this.bindGiftCard.extraOne;
                this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              }
            } else {
              this.bindGiftCard.account =
                  result.data.data.accountBind.bindAccount;
              this.bindGiftCard.accountName =
                  result.data.data.accountBind.accountName;
              this.bindGiftCard.extraOne =
                  result.data.data.accountBind.extraOne;
              this.bindGiftCard.extraTwo =
                  result.data.data.accountBind.extraTwo;
              this.updateForm.account = this.bindGiftCard.account;
              this.updateForm.accountName = this.bindGiftCard.accountName;
              this.updateForm.extraOne = this.bindGiftCard.extraOne;
              this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              this.convertStatus = result.data.data.updateLog.status;
              if (result.data.data.updateLog.status == 1) {
                this.convertShowClose = false;
                this.convertCloseOnPressEscape = false;
                this.convertCloseOnClickModal = false;
                this.convertTitle = this.$t("convert.i_info_ing");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              } else if (result.data.data.updateLog.status == 3) {
                this.convertTitle = this.$t("convert.i_infoN");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              }
            }
          }
        } else if (result.data.code == -2) {
          this.convertInfoParam.check = result.data.data.check;
          this.convertInfoParam.failMessage = result.data.message;
          this.convertInfoParam.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    toUpdateGiftCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/updateBind",
        method: "post",
        params: this.updateForm
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$router.push("/convert");
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.updateDialog = false;
    },
    goConvert() {
      this.convertInfo.itemId = this.convertRadio;
      this.convertInfo.account = this.bindGiftCard.account;
      this.convertInfo.accountName = this.bindGiftCard.accountName;
      this.convertInfo.extraOne = this.bindGiftCard.extraOne;
      this.convertInfo.extraTwo = this.bindGiftCard.extraTwo;
      this.isLoading = true;
      this.$axios({
        url: "/webGift/exchange",
        method: "post",
        params: this.convertInfo
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.convertDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    onConvert() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/queryBind?cardId=" + this.giftCard.cardId,
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        //console.log(result.data);
        if (result.data.code == 1) {
          if (result.data.data.isActivation == false) {
            this.convertBindDialog = true;
          } else {
            if (
                result.data.data.updateLog == null ||
                result.data.data.accountBind == null
            ) {
              if (result.data.data.accountBind == null) {
                this.isLoading = true;
                this.$axios({
                  url: "/webGift/bind",
                  method: "post",
                  params: this.bindGiftCard
                }).then((result) => {
                  this.isLoading = false;
                  if (result.data.code == 1) {
                    this.goConvert();
                  } else {
                    this.$message({
                      showClose: true,
                      message: result.data.message,
                      type: "error",
                      center: true
                    });
                  }
                });
              } else {
                this.goConvert();
              }
            } else {
              this.goConvert();
            }
          }
        } else if (result.data.code == -2) {
          this.convertInfoParam.check = result.data.data.check;
          this.convertInfoParam.failMessage = result.data.message;
          this.convertInfoParam.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    toConvert(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                      ["extraOne"],
                      (validErr) => {
                        if (!validErr) {
                          if (this.giftCard.forms.length >= 3) {
                            this.$refs[formName].validateField(
                                ["extraTwo"],
                                (validErr) => {
                                  if (!validErr) {
                                    this.checkConvertDialog = true;
                                  }
                                }
                            );
                          } else {
                            if (!validErr) {
                              this.checkConvertDialog = true;
                            }
                          }
                        }
                      }
                  );
                } else {
                  if (!validErr) {
                    this.checkConvertDialog = true;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.checkConvertDialog = true;
            }
          }
        }
      });
    }
  },
  created() {
    const giftCards = JSON.parse(sessionStorage.getItem("giftCards"));
    this.$store.state.goldNum = JSON.parse(sessionStorage.getItem("goldNum"));
    giftCards.forEach((giftCard) => {
      if (giftCard.cardId == this.id) {
        this.giftCard = giftCard;
      }
    });
    this.currencyId = this.giftCard.currencies[0].currencyId;
    this.items = this.giftCard.currencies[0].items;
    this.convertRadio = this.giftCard.currencies[0].items[0].itemId;
    this.bindGiftCard.cardId = this.giftCard.cardId;
    this.updateForm.cardId = this.giftCard.cardId;
    this.regexps = this.giftCard.forms[0].regexps;
    this.regexpsTips = this.giftCard.forms[0].regexpsTips;
    this.queryBindCard();
    this.getIndex();
    this.getTaskList();
  }
};
</script>
<style>
.qing{

  line-height: 40px;
  height: 40px;
  width: 85%;
  margin: 0 auto;
background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/shenqing.png");
  background-repeat: no-repeat;
  background-size: 100%,100%;
}
.input{
  margin-bottom: 60px;
  margin-left: 4%;
  width: 90%;
  height: 40px;
  background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/input.png");
}
.payNum{
  margin-top: 20px;
display: flex;
  font-size: 15px;
  color: rgba(151, 145, 188, 1);
  text-align: left;
  padding-left: 1.5em;
}

.divs{
  border-radius: 10px;
  position: relative;
  background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/juxing2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 28%;
  height: 110px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 15px;
  float: left;
}
.divs img{
  width: 100%;
  height: 100%;
}
.word {
  line-height: 1.5;
  width: 90%;
  height:auto;
  font-size: 10px;
  margin-top: 10px;
  color: rgba(151, 145, 188, 1);
  text-align: left;
  padding-left: 2em;
}
.bgp {
  position: relative;
  width: 292px;
  height: 700px;
  opacity: 1;
  margin: auto;
  background:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
}
#header {
  width: 292px;
  height: 52px;
  background:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/7.png");
}
#header2{
  width: 292px;
  height: 32px;
}
#gold{
  width: 50px;
  height: 34px;
  float: left;
}

#goldNum{
  width: 40px;
  height: 34px;
  font-size: 34px;
  margin-left: 10PX;
  float: left;
}
#gd{
  width: 100%;
  height: 34px;
}
</style>