<style>
.index-{
  position: relative;
display: flex;
  height: 8%;
  margin-top: 2%;
  margin-bottom: 1%;
}
.index-gold{
  position: absolute;
  top: 37%;
  width: 14%; aspect-ratio: 1 / 1;
  float: left;
  margin-left: 6%
}
.index-gold img{
  width: 100%;height: 100%;
}

.index-uid{
  margin-top: 10%;
  display: flex;
  align-items: center;
  position: absolute;
  width: 30%;height: 60%;
  float: left;
  left: 24%;
  font-size: 10vw;
  color: white;
}

@media screen and (min-width: 500px) {
  .index-uid {
    font-size: 50px; /* 当视口宽度大于等于1200px时，设置固定的字体大小为24px */
  }

}
.index-time{
  width: 6%;
  float: left;
  position: absolute;
  margin-left: 10%;
  left: 35%;
  top: 13%;
}
.index-time img{
  width: 100%;height: 100%;
}
.con{
width: 100%;
  height: auto;
  margin-top: 30px;
  float: left;
  position: relative;
}
.con_img{

  width: 20%;
  height: 5%;
}
.span-{
  float: left;position: absolute;bottom: 2px;left: 21%;color:rgba(139, 140, 175, 1);
  width: 34%; /* 设置元素宽度 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 文本截断并显示省略号 */
}
</style>
<template>
  <div id="bg">
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="100%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ data.userInfo.failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    <el-dialog :title="$t('index.i_contact')" :visible.sync="findDialog" width="100%" center :closeOnPressEscape="true" >
      <div style="height:200px;position: relative" >
        <div style="width: 100%;height: 6%">
        </div>
        <div style="width: 100%;height: 20%;color: rgba(139, 140, 175, 1);text-align: center; font-size: 15px;line-height: 2%">
          {{ $t('index.i_browserOpen') }}</div>
        <div style="width: 100%;height: 1px">
          <img style=" position:absolute; width: 100%" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/line.png"/>
        </div>
        <div class="con">
          <img style="float: left" class="con_img" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%26.png"/>
          <h3 style="float: left;position: absolute;bottom: 4%;left: 30%;color: white">Meta</h3>
          <span class="span-" ref="textToCopy">{{ contact }}</span>
          <img @click="copyText"  style="position: absolute;height:80%;right: 0px; width:24%; float: left" class="con_img" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%203.png"/>
        </div>
      </div>
    </el-dialog>
  <div id="head">
<img @click="flushed" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/flush.png" style=" position: absolute;margin:auto;top: 0;bottom: 0;right: 5%; width: 7%; aspect-ratio: 1 / 1;float: right"/>
<img @click="goApp" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%201.png" style=" position: absolute;margin:auto;top: 0;bottom: 0;left: 5%;width: 7%;aspect-ratio: 1 / 1;float: left"/>
  </div>
    <div class="index-">
      <div class="index-gold">
        <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/gold.png"/>
      </div>
      <div class="index-uid">
        {{data.userInfo.goldNum}}
      </div>
      <div class="index-time" @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/goldLog')">
            <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E8%AE%B0%E5%BD%95.png"/>
      </div>
    </div>
      <p style="margin-left:3%;float: left;color:  rgba(0, 215, 115, 1);height:2%;width: 20%;margin-top: 10%;">uid:&nbsp;{{data.userInfo.uid}}</p>
    <div id="con" @click="findDialog=true" style="color:white;margin-right: 5%">
      <p style="padding-bottom: 20px">{{$t('index.i_contact')}}</p>
    </div>
    <div class="together" style="margin-top: 30%;margin-left: 5%">
      <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%9B%BE%E5%B1%823.png" style=" aspect-ratio: 1 / 1;height: 100%;float: left"/>
      <p style="width: 17%;height:1%;margin-left:25px;color: white;margin-top: 10%"  >{{ $t('convert.i_convert') }}</p>
    </div>
    <div class="together" style="margin-top: 5px;height: 6%;margin-bottom: 15%">
      <img @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/convert')" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/pay.png" style=";width: 90%"/>
    </div>
    <p style="width: 96%;height: 2%;text-align: left;margin-left: 4%;color: white">{{ $t('task.i_dailyTasks') }}</p>
    <div class="scroll-container" >
      <div class="scroll-content">
              <div class="together2" style="height: 150px">
                <div class="scroll-item"  v-for="task in taskData.dayTasks" :key="task.id">
                  <div style="text-align: left;color:  rgba(171, 170, 201, 1); font-size: 14px;padding:0.7em;  line-break: anywhere; ">{{task.taskTitle}}<span style="white-space:normal" v-if="task.taskType != 1">({{task.currentNum}}/{{task.taskCondition}})</span></div>
                  <div style="margin-top: 7px;text-align: left;">
                    <span style=" position: absolute;bottom: 60px;left: 28px; vertical-align: middle;color:rgba(255, 237, 129, 1);">+{{task.taskRewardGold}} </span>
                    <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;position: absolute;bottom: 60px;left: 60px; vertical-align: middle;color:rgba(255, 237, 129, 1);"/>
                  </div>
                  <div style="float: right;margin-top: 12px;margin-right: 2%">
                    <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goApp()" style="  position:absolute; bottom: 12px;left: 15px; background-color: rgba(255, 187, 4, 1);;border-radius: 30px;color: black;border: none;min-width: 26%;">领取</el-button>
                    <el-button v-else @click="goAppType=0;goApp()" style=" position:absolute; bottom: 12px;left: 15px; background-color: rgba(37, 97, 165, 1);border-radius: 30px;color: white;border: none;min-width: 26%;">GO</el-button>
                  </div>
                </div>
              </div>
      </div>
    </div>

    <p style="width: 96%;height: 2%;text-align: left;margin-left: 4%;color: white">{{$t('task.i_achievement')}}</p>
    <div class="scroll-container" >
      <div class="scroll-content">
        <div class="together2" style="height: 150px">
          <div class="scroll-item"  v-for="task in taskData.achievementTasks" :key="task.id">
            <div style="text-align: left;color:  rgba(171, 170, 201, 1); font-size: 14px;padding:0.7em;
      line-break: anywhere; ">{{task.taskTitle}}<span v-if="task.taskType != 1">({{task.currentNum}}/{{task.taskCondition}})</span></div>
            <div style="margin-top: 7px;text-align: left;">
              <span style=" position: absolute;bottom: 60px;left: 28px; vertical-align: middle;color:rgba(255, 237, 129, 1);">+{{task.taskRewardGold}} </span>
              <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;position: absolute;bottom: 60px;left: 60px; vertical-align: middle;color:rgba(255, 237, 129, 1);"/>
            </div>
            <div style="float: right;margin-top: 12px;margin-right: 2%">
              <el-button v-if="task.currentNum>=task.taskCondition" @click="goAppType=1;goAppTask()" style="  position:absolute; bottom: 12px;left: 15px; background-color: rgba(255, 187, 4, 1);;border-radius: 30px;color: black;border: none;min-width: 76px;">领取</el-button>
              <el-button v-else @click="goAppType=0;goAppTask()" style=" position:absolute; bottom: 12px;left: 15px; background-color: rgba(37, 97, 165, 1);border-radius: 30px;color: white;border: none;min-width: 26%;">GO</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="together" style="height: 80px">
      <h2 style="height: 100%;width: 100%;text-align: center;align-items:center;color:  rgba(0, 215, 115, 1);line-height: 80px">{{$t('index.i_leaderboard')}}</h2>
    </div>
    <div class="together " style="width: 86%;margin-left: 42px;margin-bottom: 20px;position: relative" >
      <div class="name" style="text-align: center;position: absolute;left: 8%">{{ $t('leaderboard.i_name') }}</div>
<!--      <div class="uid" style="text-align: center">UID</div>-->
      <div class="gold" style="text-align: center">{{ $t('leaderboard.i_totalCoins') }}</div>
    </div>
<div style="height:auto">
     <div style="" v-for="(leaderboard, index) in goldLeaderboard.leaderboardInfo" :key="index" >
       <div style="width: 100%; aspect-ratio: 6 / 1;position: relative">
      <div v-if="index<101" style="float: left">
        <div class="ph">
          <div :style="{'text-align': 'left','float': 'left','width': nameWith+'%','vertical-align': 'middle'}">
            <span style="vertical-align: middle;width: 30%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;color: white;margin-left: 5%;margin-top: 5%">{{leaderboard.userName}}</span>
          </div>
          <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '4%','width': goldWidth+'%'}">
                  <span style="vertical-align: middle;margin-right: 100%;color: white;">{{leaderboard.number}} </span>
                </span>
        </div>
        <div style="position: absolute;width: 76%; aspect-ratio: 869 /108;">
          <img :src="leaderboard.icon" style="aspect-ratio: 1 / 1; height:100%;border-radius: 50%;position: absolute;left: 5%" />
          <img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E6%A4%AD%E5%9C%86%201%20%E6%8B%B7%E8%B4%9D.png" style="position: absolute;box-sizing: border-box;height:40%;vertical-align: middle;border-radius: 30px;left:13%;" />
          <div style="position: absolute;top: 2px;color: white;font-size: 12px;left: 14%">{{index+1}}</div>
        </div>
      </div>
</div>
      </div>
  <div style="width: 100%; aspect-ratio: 12 / 1;"></div>
</div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      checkDialog: false,
      findDialog:false,
      userInfoDialog: false,
      taskShow: 0,
      taskData: {},
      urlSchemes: "",
      goAppType: 0,
      actLeaderboard: 1,
      goldLeaderboard: {
        myRanking: {},
        leaderboardInfo: []
      },
      nameWith: 0,
      goldWidth: 0,
      leaderboard: {
        check: "",
        failMessage: "",
        appScheme: ""
      },
      task: {
        appName: "",
        check: "",
        failMessage: "",
        appScheme: ""
      },
      data: {
        appName: "",
        userInfo: {
          uid: 0,
          goldNum: 0,
          inviteCode: "",
          appScheme: "",
          check: "",
          failMessage: ""
        },

      },
      contactDrawer: false,
      contact: "https://www.facebook.com/"
    };
  },
  methods: {
    toGoldLeaderboard() {
      this.goldLeaderboard.leaderboardInfo = {};
      this.isLoading = true;
      this.$axios({
        url: "/webGift/goldLeaderboard",
        method: "get"
      }).then((result) => {
        console.log(result)
        this.isLoading = false;
        if (result.data.code == 1) {
          this.goldLeaderboard = result.data.data;
          this.$store.state.goldNum = result.data.data.goldNum;
          //console.log(this.goldLeaderboard);
          if (this.goldLeaderboard.leaderboardInfo.length > 3) {
            var text = this.goldLeaderboard.leaderboardInfo[3].number + "";
            this.goldWidth = 14 + text.length * 3;
            this.nameWith = 100 - this.goldWidth;
          } else {
            var text = this.goldLeaderboard.myRanking.number + "";
            this.goldWidth = 14 + text.length * 3;
            this.nameWith = 100 - this.goldWidth;
          }
        } else if (result.data.code == -2) {
          this.leaderboard.check = result.data.data.check;
          this.leaderboard.failMessage = result.data.message;
          this.leaderboard.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    copyText() {
      const contact = this.contact;

      navigator.clipboard.writeText(contact)
          .then(() => {
            alert("已成功复制文本: " + contact);
          })
          .catch((error) => {
            console.error('复制失败:', error);
          });
    },
    flushed(){
    location.reload();
    },
    getTaskList() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/task",
        method: "get",
        params: {
          pageNum: 1
        }
      }).then((result) => {
        this.isLoading = false;
        console.log(result.data);
        this.taskData = result.data.data;
        if (result.data.code == 1) {
          this.$store.state.goldNum = result.data.data.goldNum;
          this.urlSchemes = result.data.data.appScheme;
          this.task.appName = result.data.data.appName;
        } else if (result.data.code == -2) {
          this.task.check = result.data.data.check;
          this.task.failMessage = result.data.message;
          this.task.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getIndex() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.data = result.data.data;
          this.data.userInfo.check = result.data.data.check;
          this.data.userInfo.failMessage = result.data.data.failMessage;
          this.$store.state.goldNum = result.data.data.userInfo.goldNum;
          localStorage.setItem("appScheme", this.data.userInfo.appScheme);
          if (result.data.data.webAppId == 10) {
            this.contact = "https://t.me/";
          }
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    goApp() {
      if (this.goAppType == 0) {
        window.location.href = this.urlSchemes + "task";
      } else if (this.goAppType == 1) {
        window.location.href = this.urlSchemes + "task";
      }
    },
    goAppTask() {
      window.location.href = this.data.userInfo.appScheme+"task";
    },
    go() {
      if (this.data.userInfo.check == "login") {
        window.location.href = this.data.userInfo.appScheme + "login";
      } else {
        window.location.href = this.data.userInfo.appScheme;
      }
    }
  },
  created() {
    if (this.$route.query.token != null) {
      localStorage.setItem("token", this.$route.query.token);
    }
    this.toGoldLeaderboard();
    this.getIndex();
    this.getTaskList();
  }
};
</script>

<style>
#bg {
  min-height: calc(100vh - 150px); /* 假设你的头部和底部加起来的高度是150px */
  position: relative;
  overflow: paged-y;
  max-height: 1050px;
  max-width: 500px;
  width: 100%;
  height: 100%;
  opacity: 1;
  margin: auto;
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
  background-size:cover ;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
#head {
  position: relative;
  width: 100%;
  height: 5%;
  background:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/7.png");
}
html{
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 100%;
  overflow-x: hidden;
  background-size:cover ;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

}

#con{
  width: 51%;
  float: right;
  height: 3%;
  line-height: 3%;
  opacity: 1;
  margin-right: 2%;
  margin-top: 8%;
  background:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E7%9F%A9%E5%BD%A2%205.png");
}

.together{
  width: auto;
  height: 20px;
}
.together2{
  height: auto;
  width: auto;
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: flex-start; /* 子元素靠左排列 */
}
.name{
  width: 20%;
  height: 20px;
  float: left;
  color: white;
}
.uid{
  width: 20%;
  height: 20px;
  float: left;
  margin-left: auto;
  margin-right: 10%;
  color: white;
}
.gold{
  width: 35%;
  height: 20px;
  float: right;
  color: white;
}
.scroll-container {
  width: auto;
  height: 150px;
  overflow-x: scroll; /* 允许水平滚动 */
  overflow-y: hidden; /* 隐藏垂直滚动条 */
  -ms-overflow-style: none; /* 隐藏 IE 的滚动条 */
  scrollbar-width: none; /* 隐藏 Firefox 的滚动条 */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* 隐藏 Chrome 和 Safari 的滚动条 */

}

.scroll-content {
  white-space: nowrap;
  display: flex;
}

.scroll-item {
white-space:normal;
  float: left;
  position: relative;
  width: 100px;
  margin-left: 20px;
  height: 100%;
  background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E7%9F%A9%E5%BD%A210.png");
  background-size: contain; /* 让背景图片完全显示并铺满 div */
  background-repeat: no-repeat

}
.ph{
  position: absolute;
   left: 16%;
  margin-right: 4%;
  float: left;
  width: 76%;
  aspect-ratio: 869 /108;
  background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E7%9F%A9%E5%BD%A2%206.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

</style>