<template>
  <div id="bg"v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="head">
      <img @click="flushed" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/flush.png" style="margin-right: 15px;margin-top:15px; width: 21px;height: 21px;float: right"/>
      <img  @click="$router.push('/index')" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%201.png" style="margin-left: 15px;margin-top:15px; width: 21px;height: 21px;float: left"/>
    </div>
    <div @click="$router.push('/convertLog')" id="header3">
      <div class="ins1"><img src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%9B%BE%E5%B1%823.png" style="width: 18px;height: 18px;margin-top: 17px"/></div>
      <div class="ins3"> <p style="color: white">{{ $t('convert.i_GiftCard') }}</p> </div>
      <div class="ins2"><img @click="$router.push('/convertLog')" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E8%AE%B0%E5%BD%95.png" style="width: 18px;height: 18px;float: right;margin-right:10px;margin-top: 10px"/></div>
      <div class="ins4"> <p @click="$router.push('/convertLog')" style="color: rgba(104, 106, 145, 1);font-size: small;position: absolute;right: 30px">{{ $t('convert.i_convertLog') }}
      </p> </div>
    </div>
    <router-link :to="{name: 'convertInfo', params: {id: giftCard.cardId}}" tag="div" class="gitCardList" v-for="giftCard in giftCards" v-bind:key="giftCard.cardId">
      <img :src="giftCard.image" style="position: absolute;width: 14%; aspect-ratio: 1.8 / 1;top: 28%;left: 8%" />
      <span style="height:20px;font-size: 17px;color: white;position:absolute;top: 35%;;left:38%;line-height: 20px">{{giftCard.name}}</span>
    </router-link>
    <div v-if="giftCards==''">
      <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
      <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      checkDialog: false,
      giftCards: [],
      convert: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    flushed(){
      location.reload();
    },
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convert.check == "login") {
        window.location.href = this.convert.appScheme + "login";
      } else {
        window.location.href = this.convert.appScheme;
      }
    },
    getGiftCardList() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/convert",
        method: "get"
      }).then((result) => {
        console.log(result)
        this.isLoading = false;
        if (result.data.code == 1) {
          this.giftCards = result.data.data.giftCards;
          sessionStorage.setItem(
              "giftCards",
              JSON.stringify(result.data.data.giftCards)
          );
          sessionStorage.setItem(
              "goldNum",
              JSON.stringify(result.data.data.goldNum)
          );
          this.$store.state.goldNum = result.data.data.goldNum;
        } else if (result.data.code == -2) {
          this.convert.check = result.data.data.check;
          this.convert.failMessage = result.data.message;
          this.convert.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    }
  },
  created() {
    this.getGiftCardList();
  }
};
</script>

<style>
html{
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
  background-size: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 100%;
}

.gitCardList{
  position: relative;
  margin-left: 15px;
 background-image: url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/blueBg.png");
  background-size: 100% 95%;
  background-repeat: no-repeat;
  width: 92%;
  aspect-ratio: 3.5 / 1;
  margin-top: 20px;
  margin-right: 5%;
}

.gitCard img{
  width: 100%;
  height: 100%;
  float: left;
  margin-left: 5%;
}

#header3{
  position: relative;
  width: 100%;
  height: 3%;
  margin-bottom: 20px;
}
.ins1 {
  position: absolute;
  width: 10%;
  height: 100%;
  float: left;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 4px;
}
.ins2 {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 10%;
  height: 100%;
  right: 2%;
}
.ins3 {
  position: absolute;
  width: 30%;
  height: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 20px;
}
.ins4 {
  position: absolute;
  width: 60%;
  height: 100%;
 margin: auto;
  right: 4%;
  top: 0;
  bottom: 0;
}



</style>