<template>
  <div id="bg"  v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="head">
      <img  @click="flushed" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/flush.png" style="margin-right: 15px;margin-top:15px; width: 21px;height: 21px;float: right"/>
      <img  @click="$router.push('/convert')" src="https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/%E5%88%86%E7%BB%84%201.png" style="margin-left: 15px;margin-top:15px; width: 21px;height: 21px;float: left"/>
    </div>
    <div style="display: flex;margin-bottom: 10px" ></div>
    <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
      <div class="div1" v-for="log in convertLog" :key="log.id" style="margin-bottom: 3%;padding: 0 1%;border-radius: 8px;">
        <div  style="overflow: hidden;width: 100%">
          <div class="div11"style="margin-bottom: 12%">
            <img :src="log.icon">
          </div>
          <div style="float: left;font-size: 10px;color:white;position: absolute;top: 50%;left: 29%">
            {{log.price}}
          </div>
          <div style="font-size: 15px;position: absolute;top: 40%;right: 5%;margin-top: 2%">
                <span v-if="log.status == 1" style="color: black;">
                  {{$t('convert.i_reviewSucceeded')}}
                </span>
            <span v-else-if="log.status == 0" style="color: rgba(171, 170, 201, 1);">
                  {{$t('convert.i_underReview')}}
                </span>
            <span v-else-if="log.status == 2" style="color: red;">
                  {{$t('convert.i_auditFailure')}}
                </span>
            <span v-else-if="log.status == 3" style="color: red;">
                  {{$t('convert.i_invalid')}}
                </span>
          </div>
        </div>
          <div  style="position: absolute; width: 20px; top: 30%;left: 29%;float: left;font-size: 10px;color:rgba(171, 170, 201, 1);">
            {{log.cardName}}
          </div>
          <div style=" font-size: 15px; width:100px; color:rgba(171, 170, 201, 1);position: absolute;top: 10%;right: 1%">
            {{log.time}}
          </div>
        <div v-if="log.status == 2||log.status == 3" style="word-break:break-all ; text-align: left;font-size: 12px;color:rgba(171, 170, 201, 1);margin-bottom: 10px;margin-left: 10px;width:96%;">
          {{$t('convert.i_remark')}}：{{log.remark}}
        </div>
      </div>
      <div v-if="convertLog==''">
        <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
        <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
      </div>
    </div>





  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      checkDialog: false,
      convertLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      convertLogParam: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    flushed(){
      location.reload();
    },
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convertLogParam.check == "login") {
        window.location.href = this.convertLogParam.appScheme + "login";
      } else {
        window.location.href = this.convertLogParam.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webGift/convertLog",
          method: "get",
          params: {
            pageNum: this.pageNum,
            recordType: -1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.log.forEach((element) => {
              this.convertLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
    this.$axios({
      url: "/webGift/convertLog",
      method: "get",
      params: {
        pageNum: 1,
        recordType: -1
      }
    }).then((result) => {
      this.isLoading = false;
      if (result.data.code == 1) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else if (result.data.code == -2) {
        this.convertLogParam.check = result.data.data.check;
        this.convertLogParam.failMessage = result.data.message;
        this.convertLogParam.appScheme = result.data.data.appScheme;
        this.checkDialog = true;
      }
    });
  }
};
</script>
<style>
.div1{
  border-radius: 100%;
  width: 90%;
  margin: auto;
  display: grid;
  position: relative;
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bgbgbgbg.png") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.div11{
  height: 60px
}

.div11 img{
  margin-left: 6%;
  width: 45px;
  height: 25px;
  float: left;
  margin-top:12%;
}
.div2{
  position: relative;
  width: 90%;
  margin: auto;
  height: 100px;
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/copy3.png") ;
  background-repeat: no-repeat;
  background-size: 100%,100%;
}
.div2 span{
  position: absolute;
  font-size: 13px;
  display: flex;
  color: rgba(171, 170, 201, 1);;
  top: 10px;
  left: 100px;
  margin-left: 10px;
}
.div2 img{
  position: absolute;
  left: 5px;
  width: 13%;
  height: 50%;
  float: left;
  top:10px;
}
html{
  background-image:url("https://antstt-static-sg.oss-ap-southeast-1.aliyuncs.com/gameModuleCo/puzzle/woodenJigsawFun/web/bg.png");
  background-size: 100%;
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 100%;
}

</style>